import PropTypes from 'prop-types'
import { BackIcon, CloseIcon } from 'boards-web-ui'

import { IconWrapper } from '@ui/components/IconWrapper'
import Button from '../elements/Button'
import { SimpleButton } from '../ui/components/SimpleButton'

import styles from './Modal.module.css'

const variants = {
  small: styles.Small,
  large: styles.Large,
  basic: styles.Basic,
  smallPopup: styles.SmallPopUp,
  veryLarge: styles.VeryLarge,
}

const Modal = ({
  open,
  zIndex = 100,
  variant = 'large',
  Header,
  className,
  options = [],
  children,
  onClose,
  onBack,
  onClickOutside,
  onScroll,
}) => {
  const handleEvent = (event) => {
    const selection = window.getSelection().toString().length
    const targetContainsSelection = event.target.contains(
      window.getSelection().baseNode,
    )

    if (!(selection > 0 && targetContainsSelection)) {
      if (onClickOutside) {
        onClickOutside(event)
      }
    }
  }

  const VariantClass = variants[variant]

  const contentClassNames = className
    ? [styles.Content, className].join(' ')
    : [styles.Content]

  if (!open) {
    return null
  }

  return (
    <div
      className={styles.Root}
      onMouseUp={handleEvent}
      style={{ '--modal-z-index': zIndex }}
    >
      <div
        className={`${contentClassNames} ${VariantClass}`}
        onMouseUp={(e) => e.stopPropagation()}
        onScroll={onScroll}
      >
        {!Header && (
          <ModalHeader>
            {onClose && (
              <SimpleButton
                name="close modal"
                lightGray
                mediumBtnIcon
                customSize={38}
                onClick={onClose}
              >
                <IconWrapper>
                  <CloseIcon />
                </IconWrapper>
              </SimpleButton>
            )}
            {onBack !== undefined && (
              <SimpleButton
                name="back"
                lightGray
                mediumBtnIcon
                customSize={38}
                onClick={onBack}
              >
                <IconWrapper>
                  <BackIcon />
                </IconWrapper>
              </SimpleButton>
            )}
            {options.map(({ icon, text, onClick }) => (
              <Button
                variant="text"
                startIcon={icon}
                color="gray"
                key={text}
                onClick={onClick}
                style={{ padding: '0px 15px' }}
              >
                {text}
              </Button>
            ))}
          </ModalHeader>
        )}
        {Header && Header}
        <div className={styles.Body}>{children}</div>
      </div>
    </div>
  )
}

export const ModalHeader = ({ bgColor, children, className = '' }) => {
  const customStyles = bgColor ? { background: bgColor } : {}
  const classNames = className
    ? [styles.Header, className].join(' ')
    : styles.Header

  return (
    <div className={classNames} style={customStyles}>
      {children}
    </div>
  )
}

export const ModalHeaderLeft = ({ children }) => (
  <div className={styles.HeaderLeft}>{children}</div>
)

Modal.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'small',
    'large',
    'basic',
    'smallPopup',
    'veryLarge',
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onScroll: PropTypes.func,
  zIndex: PropTypes.number,
}

export default Modal
