import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useSelectorFactory } from '@hooks/useSelectorFactory'

import useBoard from '../../../../hooks/useBoard'
import { useUpdatesApi } from './useUpdatesApi'

import { Board, MessageProperties } from '../../../models'
import { updatesActions } from '../../../../actions'
import { updatesSavedMessage } from '../../../../selectors'

type UseCreateMessageType = {
  board: Board
  boardId?: string
  count: number

  savedProperties: MessageProperties
  postMessage: (properties: MessageProperties & { senderName?: string }) => void
  saveProperties: (data: MessageProperties) => void
  removeProperties: () => void
}
export const useCreateMessage = (): UseCreateMessageType => {
  const dispatch = useDispatch()
  const { postMessage } = useUpdatesApi()

  const { board, boardId, totalBoardMembersCount } = useBoard()

  const savedProperties = useSelectorFactory(updatesSavedMessage, boardId)

  const saveProperties = useCallback(
    (properties: MessageProperties) =>
      dispatch(
        updatesActions.saveUnPostUpdatesMessage({ board: boardId, properties }),
      ),
    [dispatch, boardId],
  )
  const removeProperties = useCallback(
    () => dispatch(updatesActions.removeUnPostUpdatesMessage(boardId)),
    [dispatch, boardId],
  )

  return {
    board,
    boardId,
    count: totalBoardMembersCount,
    savedProperties,

    postMessage,
    saveProperties,
    removeProperties,
  } as const
}
