import { useCallback } from 'react'
import { isPageNodeProfile, TPageNode, urlHelper } from 'boards-web-ui'
import { useTranslation } from 'react-i18next'

import useShortcut from '@hooks/useShortcut'

import { Folder, Node, NodeType } from 'app/models'
import { AnalyticsNodeType, EventCategories, IRest } from './models/logEvents'
import { useLogEvents } from './useLogEvents'

const usePageEvents = () => {
  const { t } = useTranslation()
  const { trigger } = useLogEvents()
  const { getShortcut } = useShortcut()

  const createPageEvent = (
    page: Folder,
    boardId: string,
    isPageBoard?: boolean,
  ) =>
    trigger(EventCategories.APP_PAGE_CREATE, {
      key_id: page.id,
      board_id: boardId,
      page_name: page.title,
      label: page.title,
      items_count: page.content.length,
      forms_count: page.content.filter((n) => n.type === NodeType.FORM).length,
      checkout_count: page.content.filter((n) => n.type === NodeType.CHECKOUT)
        .length,
      pages_in_homescreen: (!!isPageBoard).toString(),
    })
  const editPageEvent = (
    page: Folder,
    boardId: string,
    isPageBoard?: boolean,
  ) =>
    trigger(EventCategories.APP_PAGE_EDIT, {
      key_id: page.id,
      link_shortcut: getShortcut(page) || '',
      board_id: boardId,
      page_name: page.title,
      label: page.title,
      items_count: page.content.length,
      forms_count: page.content.filter((n) => n.type === NodeType.FORM).length,
      checkout_count: page.content.filter((n) => n.type === NodeType.CHECKOUT)
        .length,
      pages_in_homescreen: (!!isPageBoard).toString(),
    })
  const deletePageEvent = (
    node: Node,
    boardId: string,
    isPageBoard?: boolean,
  ) =>
    trigger(EventCategories.APP_PAGE_DELETE, {
      key_id: node.id,
      board_id: boardId,
      pages_in_homescreen: (!!isPageBoard).toString(),
    })

  const viewPageEvent = useCallback(
    ({
      boardId,
      page,
      isPageBoard,
      isActivePage,
    }: {
      boardId: string
      page: Folder
      isPageBoard?: boolean
      isActivePage: boolean
    }) => {
      trigger(EventCategories.APP_PAGE_VIEW, {
        key_id: page?.id,
        link_shortcut: getShortcut(page.shortcut) || '',
        board_id: boardId,
        pages_in_homescreen: (!!isPageBoard).toString(),
        forms_count: page.content.filter((n) => n.type === NodeType.FORM)
          .length,
        checkout_count: page.content.filter((n) => n.type === NodeType.CHECKOUT)
          .length,
        active_page: `${isActivePage}`,
      })
    },
    [trigger, getShortcut],
  )

  const pageContentClickEvent = useCallback(
    (boardId: string, rest?: IRest) =>
      trigger(EventCategories.APP_PAGE_CONTENT_CLICK, {
        board_id: boardId,
        ...rest,
      }),
    [trigger],
  )

  const pageCreateContentEvent = (
    node: Node,
    boardId: string,
    pageId: string,
    rest?: IRest,
  ) => {
    const extraParams: {
      post_submission_message?: string
      post_submission_redirect?: string
      label?: string
      content?: string
    } = {}

    if (node.type === NodeType.CHECKOUT) {
      return
    }

    if (isPageNodeProfile(node as TPageNode)) {
      extraParams.label = `${node.profile?.name}`

      if (node.profile?.title && node.profile.company) {
        extraParams.content = `${node.profile?.title}; ${node.profile?.company}`
      }

      if (node.profile?.title && !node.profile.company) {
        extraParams.content = `${node.profile?.title};`
      }

      if (!node.profile?.title && node.profile?.company) {
        extraParams.content = `${node.profile?.company};`
      }
    }

    if (node.type === NodeType.FORM) {
      if (node.form?.response) {
        extraParams.post_submission_message = (
          node.form?.response !== t('forms_thanks_message_default')
        ).toString()
      } else {
        extraParams.post_submission_message = 'false'
      }
      extraParams.post_submission_redirect = urlHelper
        .isValidUrl(node.form?.response)
        .toString()

      trigger(EventCategories.APP_FORM_CREATE, {
        key_id: node.id,
        label: node.title,
        board_id: boardId,
        try_free: 'false',
        page_id: pageId,
        type: 'form',
        google_sheets: !!node.form?.spreadsheetURL,
        responses_email: !!node.form?.email,
        ...rest,
        ...extraParams,
      })
      return
    }
    trigger(EventCategories.APP_PAGE_CONTENT_CREATE, {
      content_type: AnalyticsNodeType[node.type],
      key_id: node.id,
      label: node.title,
      board_id: boardId,
      try_free: 'false',
      button_type: node.type === NodeType.BUTTON ? node.link_type : '',
      page_id: pageId,
      ...rest,
      ...extraParams,
    })
  }
  const pageEditContentEvent = (
    node: Node,
    boardId: string,
    pageId: string,
    isGoogleAccountConnected: boolean,
  ) => {
    const extraParams: {
      post_submission_message?: string
      post_submission_redirect?: string
      google_sheets_connected?: string
      name?: string
      content?: string
    } = {}
    if (node.type === NodeType.CHECKOUT) {
      return
    }

    if (isPageNodeProfile(node as TPageNode)) {
      extraParams.name = node.profile?.name

      if (node.profile?.title && node.profile.company) {
        extraParams.content = `${node.profile?.title}; ${node.profile?.company}`
      }

      if (node.profile?.title && !node.profile.company) {
        extraParams.content = `${node.profile?.title};`
      }

      if (!node.profile?.title && node.profile?.company) {
        extraParams.content = `${node.profile?.company};`
      }
    }

    if (node.type === NodeType.FORM) {
      extraParams.post_submission_message = (!urlHelper.isValidUrl(
        node.form?.response,
      )).toString()
      extraParams.post_submission_redirect = urlHelper
        .isValidUrl(node.form?.response)
        .toString()
      extraParams.google_sheets_connected =
        (!!isGoogleAccountConnected).toString()

      trigger(EventCategories.APP_FORM_EDIT, {
        key_id: node.id,
        board_id: boardId,
        page_id: pageId,
        type: 'form',
        google_sheets: isGoogleAccountConnected.toString(),
        responses_email: (!!node.form?.email).toString(),
        ...extraParams,
      })
      return
    }

    trigger(EventCategories.APP_PAGE_CONTENT_EDIT, {
      content_type: AnalyticsNodeType[node.type],
      key_id: node.id,
      board_id: boardId,
      page_id: pageId,
      ...extraParams,
    })
  }

  const pageDeleteContentEvent = (
    node: Node,
    boardId: string,
    pageId: string,
  ) => {
    let eventCategory

    switch (node.type) {
      case NodeType.FORM:
        eventCategory = EventCategories.APP_FORM_DELETE
        break
      case NodeType.CHECKOUT:
        eventCategory = EventCategories.APP_CHECKOUT_DELETE
        break
      default:
        eventCategory = EventCategories.APP_PAGE_CONTENT_DELETE
    }

    trigger(eventCategory, {
      key_id: node.id,
      board_id: boardId,
      page_id: pageId,
      ...(![NodeType.FORM, NodeType.CHECKOUT].includes(node.type) && {
        content_type: AnalyticsNodeType[node.type],
      }),
    })
  }
  const pageInsertContentFromBoardsEvent = (
    count: number,
    boardId: string,
    pageId: string,
  ) =>
    trigger(EventCategories.APP_PAGE_CONTENT_INSERT_FROM_BOARDS, {
      items_count: count,
      board_id: boardId,
      page_id: pageId,
    })
  const pageViewContentEvent = (node: Node, boardId: string, pageId: string) =>
    trigger(EventCategories.APP_PAGE_CONTENT_VIEW, {
      content_type: AnalyticsNodeType[node.type],
      key_id: node.id,
      board_id: boardId,
      page_id: pageId,
    })

  const addPageContentMenuItemClick = (
    type: NodeType,
    isTryFree: boolean,
    isBlocked: boolean,
  ) =>
    trigger(EventCategories.APP_PAGE_ADD_CONTENT_MENU_ITEM_CLICK, {
      content_type: AnalyticsNodeType[type] || type,
      try_free: isTryFree,
      blocked: isBlocked,
    })

  return {
    createPageEvent,
    editPageEvent,
    deletePageEvent,
    viewPageEvent,

    pageCreateContentEvent,
    pageEditContentEvent,
    pageDeleteContentEvent,
    pageInsertContentFromBoardsEvent,
    pageContentClickEvent,
    pageViewContentEvent,
    addPageContentMenuItemClick,
  } as const
}

export default usePageEvents
