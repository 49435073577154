import { Trans } from 'react-i18next'

import { ShareIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import useBoard from '../../../../hooks/useBoard'

import AddShortcutIdButton from './AddShortcutIdButton'

const ShareShortcutButton = () => {
  const {
    isReadOnly,
    folder: { isPage },
  } = useBoard()

  return (
    <AddShortcutIdButton alignTooltipLeft={isReadOnly || isPage}>
      <IconWrapper>
        <ShareIcon />
      </IconWrapper>
      &nbsp;
      <Trans i18nKey={'action_share'} />
    </AddShortcutIdButton>
  )
}

export default ShareShortcutButton
