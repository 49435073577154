import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { InputPrice } from 'boards-web-ui'

import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { useCheckout } from '../hooks'

import ConnectPayPalButton from './ConnectPayPalButton'
import styles from './CheckoutSettingsPaymentInformation.module.css'

const CheckoutSettingsPaymentInformation: FC = () => {
  const { t } = useTranslation()
  const {
    isSubmitted,
    currency,
    shippingFees,
    minOrder,
    isPaypalFormConnected,
    onPaypalConnect,
    onPaypalDisconnect,
    onShippingFeesChange,
    onMinOrderChange,
    payPalIntegrationError,
    isPayPalConnectionPending,
  } = useCheckout()
  const {
    profile: { payPalAuthorization },
  } = useProfileInformation()

  return (
    <>
      <div className={styles.Prices}>
        <InputPrice
          showCurrencyOnEmpty
          value={shippingFees}
          currency={currency}
          minValue="0"
          label={t('label_shipping_fees')}
          onChange={(e) => onShippingFeesChange(e.target.value)}
        />
        <InputPrice
          showCurrencyOnEmpty
          value={minOrder}
          currency={currency}
          minValue="0"
          label={t('label_min_order')}
          onChange={(e) => onMinOrderChange(e.target.value)}
        />
      </div>
      <div className={styles.PaymentProvider}>
        <span className={styles.ButtonLabel}>
          {t('label_payment_provider')}
        </span>

        <ConnectPayPalButton
          isPayPalConnectionPending={isPayPalConnectionPending}
          errorMessage={isSubmitted ? payPalIntegrationError : ''}
          isPaypalFormConnected={isPaypalFormConnected}
          merchantPaymentsReceivable={
            payPalAuthorization?.merchantPaymentsReceivable
          }
          merchantPrimaryEmailConfirmed={
            payPalAuthorization?.merchantPrimaryEmailConfirmed
          }
          onDisconnect={onPaypalDisconnect}
          onConnect={onPaypalConnect}
        />
      </div>
    </>
  )
}

export default React.memo(CheckoutSettingsPaymentInformation)
