import React from 'react'
import { Trans } from 'react-i18next'
import { AddMembersIcon, ButtonNew, PremiumCrownIcon } from 'boards-web-ui'

import useBoard from '@hooks/useBoard'
import useBoardActions from '@hooks/useBoardActions'
import useFeatureFlags from '@hooks/useFeatureFlags'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { useFreePlanUserMenu } from '@features/boardsMenu/hooks/useFreePlanUserMenu'
import useBoardsOrder from '@features/boardsMenu/hooks/useBoardsOrder'
import { IconWrapper } from '@ui/components/IconWrapper'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

import { UpdatesTools } from '../../updates/UpdatesTools'
import { SimpleButton } from '../../../../ui/components/SimpleButton'
import ViewModeIndicator from './ViewModeIndicator'
import Share from '../../share/ShareButton'

import styles from './BoardTools.module.css'

const BoardTools: React.FC = () => {
  const { board, boardId } = useBoard()
  const { onActivate, limitReached } = useBoard({ boardId })
  const { viewOnlyLabelFeatureEnabledFeatureFlag } = useFeatureFlags()
  const { isFreePlanUser, isUserInTrialMode } = useReverseTrialContext()

  const {
    canAddMembers,
    canViewMessages,
    canEditBoard,
    canViewActivateBoardButton,
  } = useBoardActions()
  const { addMembers } = useToolbarTranslations()
  const { openPaywall } = usePaywall()
  const { isSubscribed } = useProfileInformation()
  const shouldShowViewPlansButton = !isSubscribed && !board?.isPageBoard
  const isLimitedPage = board?.isPageBoard && isFreePlanUser

  const showViewModeIndicator =
    !canEditBoard && board.isActive && viewOnlyLabelFeatureEnabledFeatureFlag

  const {
    profile: { plan },
  } = useProfileInformation()
  const numberBoardsLimit = plan?.limits?.boards
  const { boards, order } = useBoardsOrder()

  const { activeBoardsLength } = useFreePlanUserMenu({ order, boards })
  const canViewActivateFreePlanUserBoardButton =
    !board.isActive &&
    isFreePlanUser &&
    !board.isOwn &&
    activeBoardsLength < numberBoardsLimit

  return (
    <div className={styles.Root}>
      {((board.isActive && shouldShowViewPlansButton) ||
        isLimitedPage ||
        isUserInTrialMode) &&
        !canViewActivateFreePlanUserBoardButton && (
          <ButtonNew
            startIcon={
            <IconWrapper>
              <PremiumCrownIcon />
            </IconWrapper>
          }
            onClick={() =>
              openPaywall(
                isLimitedPage && board?.isPageBoard
                  ? PaywallSourceEnum.ACTIVATE_PAGE_BOARDS
                  : PaywallSourceEnum.BOARD_HEADER,
              )
            }
          >
            <Trans
              i18nKey={
                isLimitedPage
                  ? 'activate_dialog_button_activate'
                  : 'paywall_upgrade'
              }
            />
          </ButtonNew>
        )}

      {(canViewActivateBoardButton ||
        canViewActivateFreePlanUserBoardButton) && (
        <ButtonNew
          onClick={() =>
            !canViewActivateFreePlanUserBoardButton &&
            (board?.isPageBoard || board?.isOwn)
              ? openPaywall(PaywallSourceEnum.ACTIVATE_PAGE_BOARDS)
              : onActivate(PaywallSourceEnum.ACTIVATE_BOARD_SCREEN)
          }
          startIcon={
            limitReached || !canViewActivateFreePlanUserBoardButton ? (
              <IconWrapper>
                <PremiumCrownIcon />
              </IconWrapper>
            ) : null
          }
        >
          <Trans i18nKey="inactive_board_activate" />
        </ButtonNew>
      )}

      {showViewModeIndicator && (
        <ViewModeIndicator
          className={!board.receivedContent ? styles.Margin : ''}
        />
      )}

      {canAddMembers && (
        <Share
          trigger={
            <SimpleButton
              style={{ paddingRight: '12px', paddingLeft: '12px' }}
              secondary
              fitToContent
            >
              <IconWrapper className={styles.Icon}>
                <AddMembersIcon />
              </IconWrapper>
              &nbsp;{addMembers()}
            </SimpleButton>
          }
        />
      )}

      {canViewMessages && <UpdatesTools />}
    </div>
  )
}

export default BoardTools
