import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { useDispatch } from 'react-redux'

import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import { useSharedFolder } from '@hooks/useSharedFolder'
import useConfirmDialog from '@hooks/useConfirmDialog'
import { useTranslation } from 'react-i18next'
import useBulk from '@hooks/bulk/useBulk'
import { BackIcon, ButtonIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import { folderActions } from '../../../../actions'

import styles from './SharedBoardModalHeader.module.css'

type Props = {
  loading: boolean
  titleTextRef: MutableRefObject<null>
}
const SharedBoardModalHeader: FC<Props> = ({ loading, titleTextRef }) => {
  const dispatch = useDispatch()
  const RootRef = useRef<HTMLDivElement>(null)
  const confirmDialog = useConfirmDialog()
  const { t } = useTranslation()

  const { bulk, resetBulkActions } = useBulk()
  const {
    sharedFolderState: { shortcutId, folderId, boardId, folderPath },
  } = useSharedFolder()

  useEffect(() => {
    if (titleTextRef.current) {
      const observer = new IntersectionObserver(
        ([e]) => {
          if (RootRef.current) {
            if (e.intersectionRatio < 1) {
              RootRef.current.classList.add(styles.RootBoxShadow)
              e.target.classList.add(styles.StickyHeaderText)
            } else {
              RootRef.current.classList.remove(styles.RootBoxShadow)
              e.target.classList.remove(styles.StickyHeaderText)
            }
          }
        },
        {
          threshold: [1],
        },
      )

      observer.observe(titleTextRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleTextRef.current])

  const closeModal = () => {
    dispatch(folderActions.deleteFolderShortcut([]))

    dispatch(
      folderActions.updateSharedFolderData({
        shortcutId: '',
        boardId: '',
        folderId: '',
      }),
    )

    dispatch(folderActions.setSharedFolder())

    resetBulkActions()
  }

  const handleBackButtonClick = useCallback(
    (callback: () => void) => {
      if (bulk.nodes.length) {
        confirmDialog({
          title: t('clear_selected_dialog_title'),
          message: t('clear_selected_dialog_subtitle'),
          cancelText: t('action_cancel'),
          confirmText: t('action_clear'),
          onConfirm: () => {
            callback()
            resetBulkActions()
          },
        })

        return
      }

      callback()
    },
    [bulk.nodes.length, confirmDialog, resetBulkActions, t],
  )

  const handleGoBack = () => {
    if (shortcutId && !loading) {
      const user = shortcutId.split('.')[0]
      const backPath = folderPath[folderPath.length - 1]
      const newShortcutId = `${user}.${backPath}`
      dispatch(folderActions.deleteFolderShortcut(backPath))
      dispatch(
        folderActions.updateSharedFolderData({
          shortcutId: newShortcutId,
          boardId,
          folderId,
        }),
      )
    }
  }

  return (
    <div className={styles.Root} ref={RootRef}>
      <CloseButtonWithTooltip onClose={closeModal} gray />

      {folderPath.length > 0 && (
        <ButtonIcon
          size={38}
          variant="gray"
          onClick={() => {
            handleBackButtonClick(handleGoBack)
          }}
          round
        >
          <IconWrapper>
            <BackIcon />
          </IconWrapper>
        </ButtonIcon>
      )}
    </div>
  )
}

export default SharedBoardModalHeader
