import { useEffect, useMemo, useState } from 'react'
import { useDialog } from 'boards-web-ui'

import styles from '@features/checkout/components/CheckoutProviderWarning.module.css'
import LimitedByOwnerWarning from '@features/forms/components/LimitedByOwnerWarning'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useFormsEvents, {
  GOOGLE_SHEETS_INTEGRATION_SCREEN,
} from '@features/analytics/useFormsEvents'

import { FormIntegrationStatusData } from '../../../../_firebase'

type Props = {
  isOwn: boolean
  screen: GOOGLE_SHEETS_INTEGRATION_SCREEN
  initialSpreadsheetURL?: string
  integrationStatusState: FormIntegrationStatusData | null
  onGetFormIntegrationStatus: () => void
}
const useGoogleSheetConnector = ({
  isOwn,
  screen,
  initialSpreadsheetURL,
  integrationStatusState,
  onGetFormIntegrationStatus,
}: Props) => {
  const { open, close } = useDialog()
  const { appFormGoogleSheetsIntegrationClick } = useFormsEvents()
  const { isGoogleAccountConnected } = useProfileInformation()

  const [spreadsheetURL, setSpreadsheetURL] = useState<string>(
    initialSpreadsheetURL
      ? 'default'
      : integrationStatusState?.decryptedProperties?.spreadsheetURL || '',
  )
  const [statusRevalidationInProgress, setStatusRevalidationInProgress] =
    useState<boolean>(false)

  const isGoogleFormConnected: boolean = useMemo(() => {
    return (
      !!initialSpreadsheetURL ||
      (integrationStatusState?.googleAuthorization.status === 'authorized' &&
        !!spreadsheetURL)
    )
  }, [
    integrationStatusState?.googleAuthorization.status,
    spreadsheetURL,
    initialSpreadsheetURL,
  ])

  const onFormConnectToGoogleSpreadSheet = () => {
    appFormGoogleSheetsIntegrationClick(screen, isGoogleAccountConnected)

    // If it is co-editor then show decline popup;
    if (!isOwn) {
      open(<LimitedByOwnerWarning onSubmit={close} />, {
        className: styles.Clear,
        withFullScreenDialogContainer: true,
      })
      return
    }

    // If google account already have been connected just
    // update the spreadSheetURL to default and URL will be generated after save api call;
    if (integrationStatusState?.googleAuthorization.status === 'authorized') {
      setSpreadsheetURL('default')
    }

    // IF google account was not connected then open
    // GoogleSheet integration window and waiting for updates in useEffect hook
    if (
      integrationStatusState?.googleAuthorization.status !== 'authorized' &&
      integrationStatusState?.googleAuthorization.authorizationURL
    ) {
      const windowRef = window.open(
        integrationStatusState?.googleAuthorization.authorizationURL,
      )
      const timer = setInterval(() => {
        if (windowRef && windowRef.closed) {
          setStatusRevalidationInProgress(true)
          onGetFormIntegrationStatus()
          clearInterval(timer)
        }
      }, 3000)
    }
  }

  useEffect(() => {
    if (integrationStatusState?.decryptedProperties?.spreadsheetURL) {
      setSpreadsheetURL(
        integrationStatusState?.decryptedProperties?.spreadsheetURL,
      )
    }
  }, [integrationStatusState?.decryptedProperties?.spreadsheetURL])

  useEffect(() => {
    if (
      statusRevalidationInProgress &&
      integrationStatusState?.googleAuthorization.status === 'authorized' &&
      !spreadsheetURL
    ) {
      setSpreadsheetURL('default')
      setStatusRevalidationInProgress(false)
    }
  }, [
    statusRevalidationInProgress,
    integrationStatusState?.googleAuthorization.status,
    spreadsheetURL,
  ])

  const onUnlinkSpreadSheet = () => {
    // TODO will be implemented in next releases
    // open()
  }

  return {
    isGoogleFormConnected,
    isGoogleAccountConnectedPending: statusRevalidationInProgress,
    onFormConnectToGoogleSpreadSheet,
    spreadsheetURL,
    onUnlinkSpreadSheet,
  } as const
}

export default useGoogleSheetConnector
