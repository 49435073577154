import React from 'react'

import { HelpIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import { useProfileEvents } from '../../analytics/useProfileEvents'
import { useProfileTranslations } from '../../profile/hooks/useProfileTranslations'

import links from '../../../constants/links'

import styles from './SideBarFooter.module.css'

type Props = {
  className?: string
}
const SideBarFooter: React.FC<Props> = ({ className }) => {
  const { onHowToUseClick } = useProfileEvents()
  const { howToUse } = useProfileTranslations()

  const onHelp = () => {
    onHowToUseClick()
  }

  return (
    <div className={className}>
      <a
        className={styles.Item}
        href={links.helpUrl}
        target="_blank"
        onClick={onHelp}
        rel="noreferrer"
      >
        <IconWrapper className={styles.Icon}>
          <HelpIcon />
        </IconWrapper>
        {howToUse()}
      </a>
    </div>
  )
}

export default React.memo(SideBarFooter)
