import { useCallback, useState } from 'react'

import {
  FormIntegrationStatusData,
  getFormIntegrationStatus,
} from '../../../../_firebase'

type Props = {
  boardId: string
  pageId: string
  formId: string
}
const useFormIntegrationStatusState = ({ boardId, pageId, formId }: Props) => {
  const [integrationStatusStatePending, setIntegrationStatusStatePending] =
    useState<boolean>(false)
  const [integrationStatusState, setIntegrationStatusState] =
    useState<FormIntegrationStatusData | null>(null)

  const onGetFormIntegrationStatus = useCallback(async () => {
    try {
      setIntegrationStatusStatePending(true)
      const result = await getFormIntegrationStatus(boardId, pageId, formId)
      if (result) {
        setIntegrationStatusState(result)
      }
      setIntegrationStatusStatePending(false)
    } catch (e) {
      setIntegrationStatusState(null)
      setIntegrationStatusStatePending(false)
    }
  }, [setIntegrationStatusState, boardId, pageId, formId])

  return {
    integrationStatusStatePending,
    integrationStatusState,
    onGetFormIntegrationStatus,
  } as const
}

export default useFormIntegrationStatusState
