import React, { FC, memo } from 'react'

import { GoogleSheetConnect } from '@features/googleSpreadsheet'

import { useCheckout } from '../hooks'

const CheckoutGoogleSheetsConnector: FC = () => {
  const {
    spreadsheetURL,
    onUnlinkSpreadSheet,
    onFormConnectToGoogleSpreadSheet,
    isGoogleFormConnected,
    isGoogleAccountConnectedPending,
    integrationStatusStatePending,
  } = useCheckout()

  return (
    <GoogleSheetConnect
      integrationStatusStatePending={integrationStatusStatePending}
      isGoogleAccountConnectedPending={isGoogleAccountConnectedPending}
      isGoogleFormConnected={isGoogleFormConnected}
      spreadsheetURL={spreadsheetURL}
      onUnlinkSpreadSheet={onUnlinkSpreadSheet}
      onConnectAccountToGoogleSheets={onFormConnectToGoogleSpreadSheet}
    />
  )
}

export default memo(CheckoutGoogleSheetsConnector)
