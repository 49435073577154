import { useCallback } from 'react'
import useShortcut from '@hooks/useShortcut'
import { getRootFolderId } from '@helpers/NodeIdGenerator'

import { Node, NodeType } from 'app/models'
import { ViewSource } from '@features/analytics/models/sourceEvents'
import { DestinationPickerSourceEventEnum } from '@features/analytics/useDestinationPickerEvents'
import { AnalyticsNodeType, EventCategories, IRest } from './models/logEvents'
import { useLogEvents } from './useLogEvents'

const useFolderEvents = () => {
  const { trigger } = useLogEvents()
  const { getShortcut } = useShortcut()

  const createFolderEvent = (
    node: Node,
    boardId: string,
    source: ViewSource.board | ViewSource.destinationPicker,
    rest?: IRest,
  ) =>
    trigger(EventCategories.APP_FOLDER_CREATE, {
      board_id: boardId,
      key_id: node.id,
      source,
      label: node.title,
      ...rest,
    })
  const editFolderEvent = () => trigger(EventCategories.APP_FOLDER_EDIT)
  const deleteFolderEvent = (node: Node, boardId: string) =>
    trigger(EventCategories.APP_FOLDER_DELETE, {
      board_id: boardId,
      key_id: node.id,
    })
  const viewFolderEvent = useCallback(
    (boardId: string, folderId: string, activeBoard: boolean) =>
      trigger(EventCategories.APP_FOLDER_VIEW, {
        key_id: folderId,
        board_id: boardId,
        root_folder: (getRootFolderId(boardId) === folderId).toString(),
        active_board: `${activeBoard}`,
      }),
    [trigger],
  )

  const viewWebFolderEvent = useCallback(
    (joinBoard: boolean, copyRestricted: boolean) =>
      trigger(EventCategories.APP_WEB_FOLDER_VIEW, {
        join_board: joinBoard,
        copy_restricted: copyRestricted,
      }),
    [trigger],
  )

  const joinBoardEvent = useCallback(
    (
      source: string,
      boardId: string | undefined,
      folderId: string | undefined,
    ) =>
      trigger(EventCategories.APP_JOIN_BOARD, {
        source,
        root_folder: getRootFolderId(boardId) === folderId,
      }),
    [trigger],
  )

  const folderCreateContentEvent = (
    node: Node,
    boardId: string,
    rest?: IRest,
  ) =>
    trigger(EventCategories.APP_CONTENT_CREATE, {
      content_type: AnalyticsNodeType[node.type],
      board_id: boardId,
      key_id: node.id,
      try_free: 'false',
      link_shortcut: getShortcut(node) || '',
      button_type: node.link_type,
      label: node.title,
      content: node.text,
      ...rest,
    })
  const folderEditContentEvent = (node: Node, boardId: string) =>
    trigger(EventCategories.APP_CONTENT_EDIT, {
      content_type: AnalyticsNodeType[node.type],
      board_id: boardId,
      key_id: node.id,
      link_shortcut: getShortcut(node) || '',
      button_type: node.link_type,
      label: node.title,
      content: node.text,
    })
  const folderDeleteContentEvent = (boardId: string, node?: Node) =>
    trigger(EventCategories.APP_CONTENT_DELETE, {
      content_type: node ? AnalyticsNodeType[node.type] : 'selection',
      board_id: boardId,
      key_id: node ? node.id : '',
    })
  const folderViewContentEvent = (
    nid: string,
    nodeType: NodeType,
    boardId: string,
  ) =>
    trigger(EventCategories.APP_CONTENT_VIEW, {
      content_type: AnalyticsNodeType[nodeType],
      board_id: boardId,
      key_id: nid,
    })
  const folderCopyContentEvent = ({
    count,
    boardId,
    isActive,
    contentType,
    source,
  }: {
    count: number
    boardId: string
    isActive: boolean
    contentType: string
    source: DestinationPickerSourceEventEnum
  }) =>
    trigger(EventCategories.APP_CONTENT_COPY, {
      content_type: contentType,
      items_count: count,
      count,
      source,
      link_shortcut: '',
      board_id: boardId,
      active_board: isActive,
    })

  const folderMoveContentEvent = ({
    count,
    contentType,
  }: {
    count: number
    contentType: string
  }) =>
    trigger(EventCategories.APP_CONTENT_MOVE, {
      content_type: contentType,
      items_count: count,
      count,
    })

  const folderShareContentEvent = (
    count: number,
    boardId: string,
    node?: Node,
  ) =>
    trigger(EventCategories.APP_CONTENT_SHARE, {
      content_type: node ? AnalyticsNodeType[node.type] : '',
      items_count: count,
      count,
      board_id: boardId,
      key_id: node?.id,
    })

  const addContentMenuItemClick = (
    type: NodeType,
    isTryFree: boolean,
    isBlocked: boolean,
  ) =>
    trigger(EventCategories.APP_ADD_CONTENT_MENU_ITEM_CLICK, {
      content_type: type,
      try_free: isTryFree,
      blocked: isBlocked,
    })

  return {
    createFolderEvent,
    editFolderEvent,
    deleteFolderEvent,
    viewFolderEvent,

    folderCreateContentEvent,
    folderEditContentEvent,
    folderDeleteContentEvent,
    folderCopyContentEvent,
    folderMoveContentEvent,
    folderShareContentEvent,
    folderViewContentEvent,
    viewWebFolderEvent,
    joinBoardEvent,
    addContentMenuItemClick,
  } as const
}

export default useFolderEvents
