import { PagesBoardIcon, SharedBoardIcon, Tag } from 'boards-web-ui'
import React from 'react'

import useBoard from '@hooks/useBoard'
import clsx from 'clsx'
import { IconWrapper } from '@ui/components/IconWrapper'
import styles from './SelectBoardItem.module.css'

interface Props {
  emoji?: string
  title?: string
  isPageBoard?: boolean
  isShared: boolean
  disabled: boolean
  onClick: () => void
  isReadMode?: boolean
  boardId?: string
}
const SelectBoardItem = ({
  emoji,
  title,
  isShared,
  disabled,
  onClick,
  isPageBoard,
  isReadMode,
  boardId,
}: Props) => {
  const {
    isReadOnly,
    isSponsored,
    isShared: isSharedSelectorState,
  } = useBoard({ boardId })

  const isDisabled = (isReadOnly && isReadMode) || disabled

  return (
    <div
      className={clsx(styles.Root, isDisabled && styles.Disabled)}
      onClick={isDisabled ? undefined : onClick}
    >
      <span className={styles.Emoji}>
        {isPageBoard ? <PagesBoardIcon /> : emoji}
      </span>

      {title}

      {isSponsored && (
        <Tag fontSize="12px" padding="5px 7px" lineHeight="14px" gray>
          Sponsored
        </Tag>
      )}

      {(isShared || isSharedSelectorState) && !isSponsored && (
        <IconWrapper>
          <SharedBoardIcon />
        </IconWrapper>
      )}
    </div>
  )
}

export default SelectBoardItem
