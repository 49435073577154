import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { Icon, LogoIcon, TrashIcon } from 'boards-web-ui'

import clsx from 'clsx'
import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'
import { SimpleButton } from '../../../../ui/components/SimpleButton'

import styles from './CreatePageBanner.module.css'

type Props = {
  unbrandedPages?: boolean
  onDelete: () => void
}
const CreatePageBanner: FC<Props> = ({ unbrandedPages, onDelete }) => (
  <div
    className={clsx(
      styles.CreatePageBanner,
      unbrandedPages && styles.UnbrandedPages,
    )}
  >
    <div>
      <Icon className={styles.Logo} size="57px" color="#222">
        <LogoIcon />
      </Icon>

      <p className={styles.Message}>Easily create a page like this 🚀</p>
    </div>

    <Tooltip
      data={
        <TooltipContent>
          <Trans i18nKey="action_delete" />
        </TooltipContent>
      }
    >
      <SimpleButton
        name="delete"
        customSize={38}
        onClick={onDelete}
        lightBlue300
        fitToContent
        className={styles.DeleteButton}
      >
        <TrashIcon className={styles.DeleteIcon} />
      </SimpleButton>
    </Tooltip>
  </div>
)

export default React.memo(CreatePageBanner)
