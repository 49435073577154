import React from 'react'
import { DialogProvider } from 'boards-web-ui'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import ReverseTrialStart from '@features/reverseTrial/components/reverseTrialStart/ReverseTrialStartDialog'
import ReverseTrialEndDialog from '@features/reverseTrial/components/reverseTrialStart/ReverseTrialEndDialog'

const ReverseTrialBlocker = () => {
  const { shouldShowStartTrialScreen, shouldShowEndOfTrialScreen } =
    useReverseTrialContext()

  return shouldShowStartTrialScreen || shouldShowEndOfTrialScreen ? (
    <DialogProvider>
      {shouldShowStartTrialScreen && <ReverseTrialStart />}
      {shouldShowEndOfTrialScreen && <ReverseTrialEndDialog />}
    </DialogProvider>
  ) : null
}

export default ReverseTrialBlocker
