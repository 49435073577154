import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import useWindowURLTracking from '@hooks/useWindowUrlChangeTraker'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useCheckoutAnalytics from '@features/analytics/useCheckoutAnalytics'
import { disablePayPalAuthorization, startingPayPalOnboarding } from '_firebase'
import profileInfoActions from '../../../../actions/profile'

type Props = {
  paymentProviders: Array<string>
  authorizationUrl?: string
  onGetFormIntegrationStatus: (
    isCheckout?: boolean | undefined,
  ) => Promise<void>
}
const usePaymentProviders = ({
  paymentProviders,
  authorizationUrl,
  onGetFormIntegrationStatus,
}: Props) => {
  const { isPayPalAccountConnected } = useProfileInformation()
  const closeIntegrationWindowRef = useRef<() => void>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    appCheckoutConnectPaypalClick,
    appCheckoutDisconnectPaypalEvent,
    appPaypalIntegrationSuccess,
  } = useCheckoutAnalytics()

  const { openWindow } = useWindowURLTracking()

  const [paymentProvidersState, setPaymentProvidersState] =
    useState<Array<string>>(paymentProviders)
  const [isPayPalConnectionPending, setIsPayPalConnectionPending] =
    useState<boolean>(false)

  const payPalIntegrationError: string = useMemo(() => {
    if (!paymentProvidersState.includes('paypal')) {
      return t('info_missing_connect_payment_provider')
    }

    return ''
  }, [paymentProvidersState, t])

  const isPaypalFormConnected = useMemo(() => {
    return isPayPalAccountConnected && paymentProvidersState.includes('paypal')
  }, [paymentProvidersState, isPayPalAccountConnected])

  useEffect(() => {
    if (
      closeIntegrationWindowRef.current &&
      isPayPalAccountConnected &&
      !paymentProvidersState.includes('paypal')
    ) {
      setPaymentProvidersState(['paypal'])

      if (closeIntegrationWindowRef.current) {
        closeIntegrationWindowRef.current()
      }

      setIsPayPalConnectionPending(false)
      appPaypalIntegrationSuccess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPayPalAccountConnected,
    paymentProvidersState,
    closeIntegrationWindowRef,
  ])

  const onPaypalConnect = useCallback(() => {
    appCheckoutConnectPaypalClick(isPayPalAccountConnected)

    if (isPayPalAccountConnected && !paymentProvidersState.includes('paypal')) {
      setPaymentProvidersState(['paypal'])
    }

    if (!authorizationUrl) {
      return
    }

    setIsPayPalConnectionPending(true)
    startingPayPalOnboarding()
    closeIntegrationWindowRef.current = openWindow(authorizationUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPayPalAccountConnected,
    paymentProvidersState,
    authorizationUrl,
    openWindow,
  ])

  const onPaypalDisconnect = useCallback(async () => {
    try {
      setIsPayPalConnectionPending(false)
      dispatch(profileInfoActions.setPayPalAuthorization('disabled'))

      await disablePayPalAuthorization()
      appCheckoutDisconnectPaypalEvent()
      onGetFormIntegrationStatus()
    } catch {
      dispatch(profileInfoActions.setPayPalAuthorization('authorized'))
    }
  }, [dispatch, onGetFormIntegrationStatus, appCheckoutDisconnectPaypalEvent])

  return {
    isPayPalAccountConnected,
    paymentProviders: paymentProvidersState,
    isPaypalFormConnected,
    payPalIntegrationError,
    onPaypalConnect,
    onPaypalDisconnect,
    isPayPalConnectionPending,
  } as const
}

export default usePaymentProviders
