import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CheckoutFormProducts,
  AdditionalFields,
  useDialog,
  TNodeCheckoutForm,
} from 'boards-web-ui'

import type {
  ICreateOrUpdateNode,
  INodeProps,
} from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { AfterSubmitBehaviourEnum } from '@features/forms/constants'
import useNodeDiscardChanges from '@features/nodeEditor/hooks/useNodeDiscardChanges'
import useCheckoutAnalytics from '@features/analytics/useCheckoutAnalytics'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { ProfileFeatures } from '@models/UserProfile'
import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'

import { isEqualObjects } from '../../../../utils/isEqual'
import { postCheckoutRequestAdapter } from '../utils'
import {
  CHECKOUT_FIELDS_ALLOW_TO_CHANGE,
  CHECKOUT_FIELDS_ALLOW_TO_CHANGE_WITHOUT_GOOGLE,
  CHECKOUT_POST_SUBMITTING_BEHAVIOUR,
} from '../constants'
import FormsSubmitWarning from '../components/FormsSubmitWarning'
import styles from '../components/CheckoutProviderWarning.module.css'

type Props = {
  initialForm?: TNodeCheckoutForm
  action: string
  isOwn?: boolean
  isNew: boolean

  initialResponsesEmail?: string
  responsesEmail: string
  responsesEmailError: string
  encryptedEmail: string

  initialSpreadsheetURL?: string
  spreadsheetURL: string | undefined
  encryptedSpreadsheetURL: string

  products: CheckoutFormProducts
  additionalFields: AdditionalFields

  currency: string
  shippingFees: string
  minOrder: string

  postSubmissionBehavior: AfterSubmitBehaviourEnum
  postSubmissionUrl: string
  postSubmissionMessage: string
  postSubmissionError: string

  isPayPalAccountConnected: boolean
  paymentProviders: Array<string>

  onSave: ICreateOrUpdateNode
  onCancel?: () => void
}
const useCheckoutApi = ({
  initialForm,
  isOwn,
  isNew,
  initialResponsesEmail,
  initialSpreadsheetURL,
  action,
  products,
  additionalFields,
  currency,
  shippingFees,
  minOrder,
  responsesEmail,
  responsesEmailError,
  postSubmissionBehavior,
  postSubmissionUrl,
  postSubmissionMessage,
  isPayPalAccountConnected,
  paymentProviders,
  spreadsheetURL,
  postSubmissionError,
  encryptedEmail,
  encryptedSpreadsheetURL,
  onSave,
  onCancel,
}: Props) => {
  const { isFeatureAvailable: isGoogleSheetsFeatureAvailable } =
    useProfileFeatureFlag(ProfileFeatures.GOOGLE_SHEETS)
  const { t } = useTranslation()
  const { appCheckoutCreate, appCheckoutEdit } = useCheckoutAnalytics()
  const { isGoogleAccountConnected } = useProfileInformation()

  const { open, close } = useDialog()
  const discardChanges = useNodeDiscardChanges()

  const [isSubmitted, setIsSubmitted] = useState<boolean>(!isNew)

  const formToSubmit: TNodeCheckoutForm = useMemo(() => {
    return postCheckoutRequestAdapter({
      action,
      title: '',
      additionalFields,
      products,
      currency,
      shippingFees,
      minOrder,
      postSubmissionBehavior,
      postSubmissionUrl,
      postSubmissionMessage,
      responsesEmail,
      spreadsheetURL,
      paymentProviders,
      encryptedEmail,
      encryptedSpreadsheetURL,
      initialSpreadsheetURL,
    })
  }, [
    action,
    additionalFields,
    products,
    currency,
    shippingFees,
    minOrder,
    postSubmissionBehavior,
    postSubmissionUrl,
    postSubmissionMessage,
    responsesEmail,
    spreadsheetURL,
    paymentProviders,
    encryptedEmail,
    encryptedSpreadsheetURL,
    initialSpreadsheetURL,
  ])

  const isEqual = useMemo(() => {
    if (!initialForm) {
      return false
    }

    return isEqualObjects(
      formToSubmit.form as unknown as Record<string, unknown>,
      {
        ...initialForm.form,
        email: initialResponsesEmail,
        spreadsheetURL: initialSpreadsheetURL,
      } as unknown as Record<string, unknown>,
      isGoogleSheetsFeatureAvailable
        ? CHECKOUT_FIELDS_ALLOW_TO_CHANGE_WITHOUT_GOOGLE
        : CHECKOUT_FIELDS_ALLOW_TO_CHANGE,
    )
  }, [
    initialForm,
    formToSubmit,
    initialResponsesEmail,
    initialSpreadsheetURL,
    isGoogleSheetsFeatureAvailable,
  ])

  const onSubmit = useCallback(() => {
    try {
      if (!initialForm) {
        appCheckoutCreate({
          isPaymentProviderConnected: isPayPalAccountConnected,
          googleSheetsConnected: !!isGoogleAccountConnected,
          googleEmailConnected: !!responsesEmail,
          productsCount: products.length,
          fieldsCount: additionalFields.length,
          currency,
        })
      } else {
        appCheckoutEdit({
          isPaymentProviderConnected: isPayPalAccountConnected,
          googleSheetsConnected: !!isGoogleAccountConnected,
          googleEmailConnected: !!responsesEmail,
          productsCount: products.length,
          fieldsCount: additionalFields.length,
          currency,
        })
      }

      onSave({
        ...formToSubmit,
        form: {
          ...formToSubmit.form,
          spreadsheetURL: formToSubmit.form.spreadsheetURL,
        },
      } as INodeProps)

      close()
    } catch (e) {
      // Nothing to do there
    }
  }, [
    close,
    formToSubmit,
    onSave,
    additionalFields?.length,
    products.length,
    isPayPalAccountConnected,
    currency,
    appCheckoutCreate,
    appCheckoutEdit,
    initialForm,
    responsesEmail,
    isGoogleAccountConnected,
  ])

  const showContinueAnywayDialog = useCallback(() => {
    open(
      <FormsSubmitWarning
        onSubmit={onSubmit}
        onCancel={close}
        title={t('Some info is missing')}
        warningMessage={
          <ul className={styles.WarningList}>
            {!isPayPalAccountConnected || !paymentProviders.length ? (
              <li>{t('label_payment_provider')}</li>
            ) : null}

            {!responsesEmail ? (
              <li>{t('info_missing_responses_email')}</li>
            ) : null}
          </ul>
        }
      />,
      {
        className: styles.Clear,
        withFullScreenDialogContainer: true,
      },
    )
  }, [
    onSubmit,
    responsesEmail,
    isPayPalAccountConnected,
    paymentProviders.length,
    close,
    open,
    t,
  ])

  const onCheckoutSave = useCallback(() => {
    setIsSubmitted(true)

    if (!isOwn) {
      return onSubmit()
    }

    if (responsesEmail && responsesEmailError) {
      return undefined
    }

    if (
      postSubmissionUrl &&
      postSubmissionBehavior === CHECKOUT_POST_SUBMITTING_BEHAVIOUR.REDIRECT &&
      postSubmissionError
    ) {
      return undefined
    }

    if (!responsesEmail) {
      return null
    }

    if (!isPayPalAccountConnected) {
      return showContinueAnywayDialog()
    }

    if (isPayPalAccountConnected && !paymentProviders.length) {
      return showContinueAnywayDialog()
    }

    return onSubmit()
  }, [
    isOwn,
    setIsSubmitted,
    isPayPalAccountConnected,
    paymentProviders.length,
    onSubmit,
    showContinueAnywayDialog,
    responsesEmail,
    responsesEmailError,
    postSubmissionUrl,
    postSubmissionBehavior,
    postSubmissionError,
  ])

  const onCheckoutCancel = useCallback(() => {
    if (!isEqual) {
      return discardChanges(close)
    }

    return onCancel && onCancel()
  }, [discardChanges, close, isEqual, onCancel])

  return {
    isSubmitted,
    onCheckoutSave,
    onCheckoutCancel,
  } as const
}

export default useCheckoutApi
