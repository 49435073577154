import { Trans } from 'react-i18next'
import { IconWrapper } from '@ui/components/IconWrapper'
import { ZoomInIcon, ZoomOutIcon, ZoomResetIcon } from 'boards-web-ui'
import { SimpleButton } from '../../../../ui/components/SimpleButton'
import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'
import { TooltipPositions } from '../../../../ui/models/tooltip'

import styles from './ZoomButtons.module.css'

interface Props {
  reset: (() => void) | null
  increase: (() => void) | null
  decrease: (() => void) | null
}

const ZoomButtons = ({ reset, increase, decrease }: Props) => {
  const handleReset = () => reset && reset()
  const handleDecrease = () => decrease && decrease()
  const handleIncrease = () => increase && increase()

  return (
    <div className={styles.Root}>
      <Tooltip
        position={TooltipPositions.BOTTOM}
        data={
          <TooltipContent className={styles.Tooltip}>
            <Trans i18nKey={'action_zoom_out'} />
          </TooltipContent>
        }
      >
        <SimpleButton
          className={decrease ? '' : styles.IconDisabled}
          mediumBtnIcon
          fitToContent
          darkGray
          small
          onClick={handleDecrease}
          disabled={!decrease}
          style={{ padding: '0px 9px' }}
        >
          <IconWrapper>
            <ZoomOutIcon />
          </IconWrapper>
        </SimpleButton>
      </Tooltip>
      <Tooltip
        position={TooltipPositions.BOTTOM}
        data={
          <TooltipContent className={styles.Tooltip}>
            <Trans i18nKey={'action_reset_zoom'} />
          </TooltipContent>
        }
      >
        <SimpleButton
          className={reset ? '' : styles.IconDisabled}
          fitToContent
          mediumBtnIcon
          darkGray
          small
          onClick={handleReset}
          disabled={!reset}
          style={{ padding: '0px 9px' }}
        >
          <IconWrapper>
            <ZoomResetIcon />
          </IconWrapper>
        </SimpleButton>
      </Tooltip>
      <Tooltip
        position={TooltipPositions.BOTTOM}
        data={
          <TooltipContent className={styles.Tooltip}>
            <Trans i18nKey={'action_zoom_in'} />
          </TooltipContent>
        }
      >
        <SimpleButton
          className={increase ? '' : styles.IconDisabled}
          fitToContent
          mediumBtnIcon
          darkGray
          small
          onClick={handleIncrease}
          disabled={!increase}
          style={{ padding: '0px 9px' }}
        >
          <IconWrapper>
            <ZoomInIcon />
          </IconWrapper>
        </SimpleButton>
      </Tooltip>
    </div>
  )
}

export default ZoomButtons
