import React, { FC } from 'react'

import { CloseIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import ButtonIcon from '../../elements/ButtonIcon'

type Props = {
  size?: number
  light?: boolean
  gray?: boolean
  className?: string
  onClose?: () => void
}
const CloseButton: FC<Props> = ({
  size = 38,
  gray,
  className,
  onClose,
  ...rest
}) => {
  return (
    <ButtonIcon
      round
      size={size}
      variant={gray ? 'gray' : undefined}
      onClick={onClose}
      className={className}
      {...rest}
    >
      <IconWrapper>
        <CloseIcon />
      </IconWrapper>
    </ButtonIcon>
  )
}

export default React.memo(CloseButton)
