import { FC, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Icon, PagePreviewEmptyStateIcon, SpinnerIcon } from 'boards-web-ui'

import useBoard from '@hooks/useBoard'
import useFolder from '@hooks/useFolder'
import { useToast } from '@hooks/useToast'
import useRouterParams from '@hooks/useRouterParams'
import useCopyShortcuts from '@hooks/useCopyShortcuts'
import { hash28 } from '@helpers/Base62'

import { ScreenNames } from '@features/analytics/useNodeEvents'
import usePageEvents from '@features/analytics/usePageEvents'
import { useSharedFolder } from '@hooks/useSharedFolder'
import { usePagePremiumLimitationMode } from '@features/editorPages/hooks/usePagePremiumLimitationMode'
import { PremiumBanner } from '@features/editorPages/components/PremiumBanner'
import clsx from 'clsx'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'
import { ProfileFeatures } from '@models/UserProfile'

import findMissedFieldsInPgeForms from '../../../../utils/findMissedFieldsInForm'

import { getFoldersWithShortcuts } from '../../../../_firebase'
import { transformNodePage } from '../../../../_firebase/listeners/transformers/NodePageTransformer'
import { transformNodeFolder } from '../../../../_firebase/listeners/transformers/NodeFolderTransformer'
import { Folder, Node } from '../../../models'
import PageNodes from '../../nodes/PageNodes'

import PreviewModal from './PreviewModal'
import { LoadFoldersWithShortcutsResponse } from '../../../../_firebase/models/shortcutDataResponse'

import styles from './PreviewPage.module.css'

interface Props {
  node: Node
  next?: () => void
  prev?: () => void
  folderShared?: Folder
  isSharedFolderMode?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
}

const PreviewPage: FC<Props> = ({
  node,
  prev,
  next,
  folderShared,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
}) => {
  const { t } = useTranslation()
  const { boardId } = useRouterParams()
  const [data, setData] = useState<LoadFoldersWithShortcutsResponse>()
  const toast = useToast()
  const {
    profile: { payPalAuthorization },
  } = useProfileInformation()

  const { isFeatureAvailable: isCheckoutFeatureAvailable } =
    useProfileFeatureFlag(ProfileFeatures.CHECKOUT)

  const {
    sharedFolderState: { shortcutId },
  } = useSharedFolder()

  const [loading, setLoading] = useState(false)
  const [generatedFolder, setGeneratedFolder] = useState<
    Folder | { content: [] }
  >({
    content: [],
  })

  const { folder: folderFromBoardId } = useFolder(boardId, node.id)

  const { getPremiumPreviewPageFolder } = usePagePremiumLimitationMode()
  const {
    showPremiumPreviewPageLimitationModeBanner,
    previewPageFolder: folder,
  } = getPremiumPreviewPageFolder(
    isSharedFolderMode && shortcutId
      ? (generatedFolder as Folder)
      : folderFromBoardId,
  )

  const { board } = useBoard()

  useEffect(() => {
    if (isSharedFolderMode && shortcutId) {
      setLoading(true)
      const sharerShortcut = `${shortcutId.split('.')[0]}`
      const shortcut = `${sharerShortcut}.${node.shortcut}`
      getFoldersWithShortcuts(shortcut)
        .then((res) => {
          if (res) {
            setData(res)
          }
        })
        .catch(() => {
          toast(t('optimistic_ui_failed'))
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.shortcut])

  useEffect(() => {
    if (data && shortcutId) {
      const folderData = data.folders[0].folder
      const isPage = true

      const newFolder = {
        id: folderData.id,
        icon: folderData.properties.icon,
        title: folderData.properties.title,
        isPage,
        shortcut: shortcutId,
        content: folderData.content.ids.map((id: string) => {
          const generateNodes = folderData.content.properties[id]
          const propertyId = hash28(generateNodes.text || '')

          const properties =
            folderData.urls?.properties &&
            folderData.urls.properties[propertyId]

          return isPage
            ? transformNodePage(id, generateNodes, properties)
            : transformNodeFolder(id, generateNodes, properties)
        }),
      } as Folder
      setGeneratedFolder(newFolder)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { viewPageEvent } = usePageEvents()
  const { copyFolderShortcut } = useCopyShortcuts()

  useEffect(() => {
    if (!isSharedFolderMode) {
      viewPageEvent({
        boardId,
        page: folder,
        isPageBoard: board.isPageBoard,
        isActivePage: Boolean(board.isActive),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId, folder?.id])

  const missedPageProperties =
    node && folder?.isPage
      ? findMissedFieldsInPgeForms({
          folder,

          isCheckoutFeatureAvailable,
          isPayPalAccountConnected:
            payPalAuthorization?.status === 'authorized',
          merchantPaymentsReceivable:
            payPalAuthorization?.merchantPaymentsReceivable,
          merchantPrimaryEmailConfirmed:
            payPalAuthorization?.merchantPrimaryEmailConfirmed,
        })
      : {}

  const nodesToRender = isSharedFolderMode
    ? generatedFolder?.content
    : folder?.content

  const isEmpty = nodesToRender.length === 0

  const renderPageContent = useMemo(() => {
    if (loading) {
      return (
        <div className={styles.FolderLoadingSpinner}>
          <Icon size="38px">
            <SpinnerIcon />
          </Icon>
        </div>
      )
    }

    if (isEmpty) {
      return (
        <div className={styles.NoNodesContainer}>
          <PagePreviewEmptyStateIcon />
          <Trans i18nKey={'page_empty_state_title'} />
        </div>
      )
    }

    return <PageNodes nodes={nodesToRender} screen={ScreenNames.PAGE_PREVIEW} />
  }, [isEmpty, loading, nodesToRender])

  return (
    <PreviewModal
      nid={node.id}
      copyText={t('action_share')}
      onCopyClick={() => copyFolderShortcut(folder)}
      prev={prev}
      next={next}
      folder={folderShared}
      isSharedFolderMode={isSharedFolderMode}
      canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
      sharedBoardId={sharedBoardId}
      sharedFolderId={sharedFolderId}
      missedIndicator={!!Object.keys(missedPageProperties).length}
    >
      <div className={styles.Root} onClick={(e) => e.stopPropagation()}>
        <div
          className={clsx(
            styles.Page,
            isEmpty && styles.EmptyPage,
            showPremiumPreviewPageLimitationModeBanner && styles.pageWithBanner,
          )}
        >
          {showPremiumPreviewPageLimitationModeBanner ? (
            <PremiumBanner isPagePreview isEmpty={isEmpty} />
          ) : null}

          {renderPageContent}
        </div>
      </div>
    </PreviewModal>
  )
}

export default PreviewPage
