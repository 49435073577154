import React, { FC, memo } from 'react'
import { FieldError, useForm } from 'react-hook-form'

import { SimpleField } from '../../../../ui/components/SimpleField'
import { SimpleButton } from '../../../../ui/components/SimpleButton'
import { SimpleDialog } from '../../../../ui/components/SimpleDialog'

import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { useCreateMessage } from '../hooks/useCreateMessage'
import { useOnMount } from '../../../../ui/hooks/useOnMount'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import { useUpdatesTranslations } from '../hooks/useUpdatesTranslations'

import { MessageProperties, UpdateProfile } from '../../../models'

import styles from './PostMessagesConfirmation.module.css'

type Props = {
  onConfirm: () => void
  properties: MessageProperties
}

export const PostMessageConfirmation: FC<Props> = ({
  onConfirm,
  properties,
}) => {
  const { profile } = useProfileInformation()
  const { count, board, postMessage } = useCreateMessage()
  const { confirmationView, addNameShown, addNameSaved } = useUpdatesEvents()
  const {
    postButtonValue,
    postConfirmationTitle,
    postConfirmationBody,
    addNamePlaceholder,
    addNameIsRequired,
    minNameLength,
    maxNameLength,
  } = useUpdatesTranslations()

  useOnMount(confirmationView)
  useOnMount(() => {
    if (!profile.name) {
      addNameShown()
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  })

  const onSubmit = ({ name }: UpdateProfile) => {
    if (!profile.name) {
      postMessage({ ...properties, senderName: name })

      if (name) {
        addNameSaved(name)
      }
    } else {
      postMessage(properties)
    }

    onConfirm()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleDialog
        title={postConfirmationTitle(profile?.name)}
        message={
          <>
            <p
              className={styles.Centred}
              dangerouslySetInnerHTML={{
                __html: postConfirmationBody(
                  count,
                  board.icon,
                  board.title,
                  profile?.name,
                ),
              }}
            />

            {!profile.name && (
              <>
                <br />
                <SimpleField
                  placeholder={addNamePlaceholder()}
                  error={errors?.name as FieldError}
                  {...register('name', {
                    required: addNameIsRequired(),
                    minLength: {
                      value: 3,
                      message: minNameLength(3),
                    },
                    maxLength: {
                      value: 30,
                      message: maxNameLength(30),
                    },
                  })}
                />
              </>
            )}
          </>
        }
      >
        <SimpleButton
          type="submit"
          primary
          large
          className={[
            styles.Button,
            !profile?.name ? styles.FullWidth : '',
          ].join(' ')}
        >
          {postButtonValue()}
        </SimpleButton>
      </SimpleDialog>
    </form>
  )
}

export default memo(PostMessageConfirmation)
