import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  LogOutLeaveBoardIcon,
  MoreIcon,
  SelectIcon,
  TrashIcon,
  useDialog,
} from 'boards-web-ui'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@hooks/useAuth'
import useBoards from '@hooks/useBoards'
import useBoardActions from '@hooks/useBoardActions'
import { useToast } from '@hooks/useToast'
import previousBoard from '@helpers/previousBoard'
import { boardIndicator, rootFolderIndicator } from '@helpers/NodeIdGenerator'

import useBoardEvents from '@features/analytics/useBoardEvents'
import useSidebarBanners from '@features/sidebar/hooks/useSidebarBanners'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

import { useUpdatesEvents } from '@features/analytics/useUpdatesEvents'
import { IconWrapper } from '@ui/components/IconWrapper'
import Menu, { MenuItem } from '../../../../ui/components/Menu'
import { SimpleButton } from '../../../../ui/components/SimpleButton'
import { DropDown, DropDownPosition } from '../../../../ui/components/DropDown'
import DeleteNodeConfirmation from './DeleteNodeConfirmation'

import useBoard from '../../../../hooks/useBoard'
import useBoardApi from '../../../../hooks/useBoardApi'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

import useRouterParams, { TabType } from '../../../../hooks/useRouterParams'

import styles from './BoardActions.module.css'

type MenuProps = {
  onItemClick: () => void
}
export const BoardActionsMenu: React.FC<MenuProps> = ({ onItemClick }) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { open, close } = useDialog()
  const { order } = useBoards()
  const { deleteBoardEvent, leaveBoardEvent } = useBoardEvents()
  const { boardShowInExtension, boardHideFromExtension } = useUpdatesEvents()
  const { selectedTab } = useRouterParams()
  const {
    deleteBoardAction,
    deleteBoardTitle,
    deleteBoardConfirmation,
    areYorSure,
    areYorSureBody,
    selectKeysAction,
    leaveBoardConfirmation,
    leaveBoardConfirmationBody,
    leaveBoardAction,
    leaveAction,
    boardDeleted,
    showPagesBoardAction,
    hidePagesBoardAction,
  } = useToolbarTranslations()
  const { deleteBoard, selectKeys, leaveBoard } = useBoardApi()
  const { board, isEmpty, boardId, folderId, isActive, permissions } =
    useBoard()
  const { canSelectNodes, canDeleteBoard, canLeaveBoard } = useBoardActions()
  const { hidePagesBoardFromChrome, showPagesBoardFromChrome } =
    useProfileInformation()
  const {
    uiState: { pagesBoardHiddenFromChrome },
  } = useSidebarBanners()
  const toast = useToast()
  const { t } = useTranslation()
  const deleteHandler = () => {
    deleteBoardEvent(boardId, isActive)
    const boardToSelect = previousBoard(order, boardId)
    deleteBoard(boardId, boardDeleted())
    close()
    if (boardToSelect) {
      navigate(
        `/${boardToSelect}/${boardToSelect
          .split('-')[1]
          .replace(boardIndicator, rootFolderIndicator)}`,
      )
    } else {
      navigate('/')
    }
  }

  const leaveHandler = () => {
    const boardToSelect = previousBoard(order, boardId)
    leaveBoard(boardId, user?.email)
    leaveBoardEvent(boardId, board.title, !!board.isActive)
    close()
    if (boardToSelect) {
      navigate(
        `/${boardToSelect}/${boardToSelect
          .split('-')[1]
          .replace(boardIndicator, rootFolderIndicator)}`,
      )
    } else {
      navigate('/')
    }
  }

  const deleteConfirmation = () =>
    open(
      <DeleteNodeConfirmation
        title={deleteBoardTitle()}
        body={deleteBoardConfirmation()}
        onCancel={close}
        onConfirm={() => {
          close()

          if (permissions?.length) {
            deleteSharedConfirmation()
          } else {
            deleteHandler()
          }
        }}
      />,
    )

  const deleteSharedConfirmation = () =>
    open(
      <DeleteNodeConfirmation
        title={areYorSure()}
        body={areYorSureBody()}
        onCancel={close}
        onConfirm={deleteHandler}
      />,
    )

  const leaveConfirmation = () =>
    open(
      <DeleteNodeConfirmation
        title={leaveBoardConfirmation()}
        body={leaveBoardConfirmationBody()}
        onCancel={close}
        onConfirm={leaveHandler}
        confirmTitle={leaveAction()}
      />,
    )

  const handleChromeExtensionMenuClick = () => {
    if (pagesBoardHiddenFromChrome) {
      showPagesBoardFromChrome()
      boardShowInExtension()
      onItemClick()
      toast(t('toast_pages_chrome_show'), true, 0, false, 'ViewOnly')
      return
    }
    hidePagesBoardFromChrome()
    boardHideFromExtension()
    onItemClick()
    toast(t('toast_pages_chrome_hide'), true, 0, false, 'HideIcon')
  }

  return (
    <Menu as="div">
      {canSelectNodes && selectedTab === TabType.CONTENT && (
        <MenuItem
          as="button"
          name="select keys"
          onClick={() => {
            selectKeys(boardId, folderId)
            onItemClick()
          }}
        >
          <SelectIcon className={styles.ItemIcon} />
          {selectKeysAction()}
        </MenuItem>
      )}

      {canDeleteBoard && (
        <MenuItem
          as="button"
          name="delete board"
          onClick={() => {
            if (isEmpty) {
              deleteHandler()
            } else {
              deleteConfirmation()
            }
            onItemClick()
          }}
        >
          <TrashIcon className={styles.ItemIcon} />
          {deleteBoardAction()}
        </MenuItem>
      )}

      {canLeaveBoard && (
        <MenuItem
          as="button"
          name="leave board"
          onClick={() => {
            leaveConfirmation()
            onItemClick()
          }}
        >
          <LogOutLeaveBoardIcon className={styles.ItemIcon} />
          {leaveBoardAction()}
        </MenuItem>
      )}

      {board.isPageBoard && (
        <MenuItem
          as="button"
          name="hide or show pages board in chrome"
          onClick={handleChromeExtensionMenuClick}
        >
          {pagesBoardHiddenFromChrome
            ? showPagesBoardAction()
            : hidePagesBoardAction()}
        </MenuItem>
      )}
    </Menu>
  )
}

type Props = {
  className?: string
}
const BoardActions: React.FC<Props> = ({ className }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <DropDown
      position={DropDownPosition.left}
      isOpen={isOpen}
      onToggle={setIsOpen}
      className={[styles.Menu, className].join(' ')}
      content={<BoardActionsMenu onItemClick={() => setIsOpen(false)} />}
    >
      <SimpleButton
        name="more"
        lightGray
        mediumBtnIcon
        customSize={38}
        isActive={isOpen}
        className={styles.MarginButton}
      >
        <IconWrapper>
          <MoreIcon />
        </IconWrapper>
      </SimpleButton>
    </DropDown>
  )
}

export default BoardActions
