import { NodeType as NodeTypeWebUI, PageNode, TPageNode } from 'boards-web-ui'

import React from 'react'
import { Trans } from 'react-i18next'
import useFolder from '@hooks/useFolder'
import useGeoLocation from '@hooks/useGeoLocation'
import { useSelectorFactory } from '@hooks/useSelectorFactory'

import NodeSelection from '../../nodes/NodeSelection'
import { Node } from '../../../models'
import { getUploadingNodes } from '../../../../selectors'

import usePageNodeTransformer from '../../nodes/hooks/usePageNodeTransformer'
import { ReactComponent as EmptyFolderStateIcon } from '../../../../icons/EmptyFolderState.svg'

import EmptyContent from './EmptyContent'
import styles from './PageSelection.module.css'

function nodeHasDarkOverlay(type: NodeTypeWebUI): boolean {
  return [NodeTypeWebUI.VIDEO, NodeTypeWebUI.IMAGE].includes(type)
}
function nodesDisabled(type: NodeTypeWebUI): boolean {
  return type === NodeTypeWebUI.AUDIO
}

interface Props {
  nodes: Node[]
  boardId: string
  folderId: string
  toggleNode: (node: Node) => void
}
const PageSelection = ({ nodes, boardId, folderId, toggleNode }: Props) => {
  const { geoData } = useGeoLocation()
  const { folder } = useFolder(boardId, folderId)
  const transformPageNode = usePageNodeTransformer()

  const transformedNodes = folder.content.map(transformPageNode)
  const uploadingNodes = useSelectorFactory(getUploadingNodes)

  if (transformedNodes.length === 0) {
    return (
      <EmptyContent
        icon={<EmptyFolderStateIcon />}
        title={<Trans i18nKey={'label_nothing_here'} />}
      />
    )
  }

  return (
    <div className={styles.Root}>
      {transformedNodes.map((nodeItem) => {
        if (uploadingNodes.includes(nodeItem.source.id)) {
          return null
        }

        return (
          <div key={nodeItem.source.id} className={styles.Item}>
            <NodeSelection
              key={nodeItem.source.id}
              isSelected={nodes.map((n) => n.id).includes(nodeItem.source.id)}
              withDarkOverlay={nodeHasDarkOverlay(nodeItem.node.type)}
              disabled={nodesDisabled(nodeItem.node.type)}
              onClick={
                nodesDisabled(nodeItem.node.type)
                  ? undefined
                  : () => toggleNode(nodeItem.source)
              }
              withMargin
              withRadioBtn
            >
              <PageNode
                node={nodeItem.node as TPageNode}
                countryAndDialCodes={{
                  dialCode: geoData?.dialCode,
                  countryCode: geoData?.countryCode,
                }}
                target="_blank"
                whiteVariant
              />
            </NodeSelection>
          </div>
        )
      })}
    </div>
  )
}

export default PageSelection
