import React, { FC, useEffect } from 'react'
import { TNodeCheckoutForm } from 'boards-web-ui'

import { CheckoutActionsIdsEnum } from '@features/checkout/models'
import { ICreateOrUpdateNode } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import useCheckoutAnalytics from '@features/analytics/useCheckoutAnalytics'

import { CheckoutProvider } from '../providers'
import CheckoutDialog from './CheckoutDialog'

type CheckoutProps = {
  isOwn: boolean
  boardId: string
  pageId: string
  advancedForms: boolean
  initialScreen?: CheckoutActionsIdsEnum
  initialNode?: TNodeCheckoutForm
  onPaywall: () => void
  onClose: () => void
  onSave: ICreateOrUpdateNode
}
const Checkout: FC<CheckoutProps> = ({
  isOwn,
  boardId,
  pageId,
  advancedForms,
  initialScreen,
  initialNode,
  onPaywall,
  onClose,
  onSave,
}) => {
  const { appCheckoutEditorView } = useCheckoutAnalytics()

  useEffect(() => {
    appCheckoutEditorView(!!initialNode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CheckoutProvider
      initialScreen={initialScreen}
      isOwn={isOwn}
      boardId={boardId}
      pageId={pageId || ''}
      initialNode={initialNode}
      advancedForms={advancedForms}
      onPaywall={onPaywall}
      onSave={onSave}
      onCancel={onClose}
      isNew
    >
      <CheckoutDialog />
    </CheckoutProvider>
  )
}

export default React.memo(Checkout)
