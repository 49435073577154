import React, { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { PagesBoardIcon, SharedBoardIcon, Tag } from 'boards-web-ui'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IconWrapper } from '@ui/components/IconWrapper'
import { useBoardsMenuTranslations } from '../hooks/useBoardsMenuTranslations'
import Tooltip, { TooltipContent } from '../../../../ui/components/Tooltip'

import { ReactComponent as VectorArrow } from '../../../../icons/VectorArrow.svg'

import styles from './MenuTitle.module.css'

type Props = PropsWithChildren<{
  inactive?: boolean
  boardId: string
  nodeId: string
  level: number
  title: string
  icon: string
  isExpanded: boolean
  isSelected: boolean
  isDisabled: boolean
  isSponsored: boolean
  hasSharedIcon: boolean
  onClick: () => void
  onToggle?: () => void
  className?: string
  isPageBoard?: boolean
}>

const MenuTitle: FC<Props> = ({
  inactive,
  boardId,
  nodeId,
  level,
  title,
  icon,
  isExpanded,
  isSponsored,
  isSelected,
  isDisabled,
  hasSharedIcon,
  onClick,
  onToggle,
  children,
  className,
  isPageBoard,
}) => {
  const { t } = useTranslation()
  const { sharedBoard } = useBoardsMenuTranslations()
  const { search } = useLocation()

  const onToggleClick = (e: {
    stopPropagation: () => void
    preventDefault: () => void
  }) => {
    e.preventDefault()

    if (onToggle && !isDisabled) {
      onToggle()
    }
  }

  const titleClass = level === 1 ? styles.BigTitle : ''

  return (
    <Link
      to={`/${boardId}/${nodeId}${search}`}
      className={clsx(
        styles.Root,
        inactive && !isPageBoard && styles.Inactive,
        isSelected && styles.Selected,
        isDisabled && styles.Disabled,
      )}
      onDoubleClick={onToggleClick}
      onClick={onClick}
    >
      <button
        name="toggle"
        aria-label="Expand or collapse Menu"
        style={{ marginLeft: `${level * 13}px` }}
        className={clsx(styles.Toggle, className)}
        onClick={onToggleClick}
        disabled={isDisabled}
      >
        <VectorArrow
          className={clsx(
            styles.ToggleIcon,
            isExpanded && !isDisabled && styles.Expanded,
          )}
        />
      </button>

      <span className={clsx(styles.TitleWrap, className)}>
        <span
          className={clsx(styles.Icon, isPageBoard && styles.PagesBoardIcon)}
        >
          {isPageBoard ? <PagesBoardIcon /> : icon}
        </span>

        <Tooltip
          container={document.body}
          data={<TooltipContent disabled={isPageBoard}>{title}</TooltipContent>}
          className={clsx(styles.Title, titleClass)}
        >
          {isPageBoard ? t('label_web_pages') : title}
        </Tooltip>
      </span>

      {isSponsored && (
        <Tag
          className={styles.Tag}
          fontSize="12px"
          padding="5px 7px"
          lineHeight="14px"
          gray
        >
          Sponsored
        </Tag>
      )}

      {hasSharedIcon && !isSponsored && (
        <Tooltip
          className={clsx(styles.SharedIcon, className)}
          data={<TooltipContent>{sharedBoard()}</TooltipContent>}
        >
          <IconWrapper>
            <SharedBoardIcon />
          </IconWrapper>
        </Tooltip>
      )}

      {children}
    </Link>
  )
}

export default React.memo(MenuTitle)
