import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SpinnerIcon, Icon } from 'boards-web-ui'
import Box from '../../../../elements/Box'
import Checkbox from '../../../../elements/Checkbox'
import CopyLink from './CopyLink'

import useConfirmDialog from '../../../../hooks/useConfirmDialog'
import useBoard from '../../../../hooks/useBoard'
import useSharePublicLink from '../hooks/useSharePublicLink'
import useShareEvents from '../../analytics/useShareEvents'
import { useToast } from '../../../../hooks/useToast'

const InviteLink = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const {
    board: { isOwn },
  } = useBoard()
  const { createInviteLinkEvent, deactivateInviteLinkEvent } = useShareEvents()
  const {
    publicKey,
    publicKeyEnabled,
    createOrEnableInviteLink,
    toogleInviteLink,
  } = useSharePublicLink()
  const confirmDialog = useConfirmDialog()

  const onToggleCheckbox = () => {
    if (!publicKeyEnabled) {
      setLoading(true)
      createOrEnableInviteLink()
        .then(() => setLoading(false))
        .catch((error) => {
          toast(error.message)
          setLoading(false)
        })
      createInviteLinkEvent()
      return
    }

    confirmDialog({
      title: t('deactivate_invite_link'),
      message: t('anyone_who_tries_to_use'),
      confirmText: t('deactivate_link'),
      cancelText: t('action_cancel'),
      onConfirm: () => {
        setLoading(true)
        toogleInviteLink()
          .then(() => setLoading(false))
          .catch((error) => {
            setLoading(false)
            toast(error.message)
          })
        deactivateInviteLinkEvent()
      },
    })
  }

  if (!isOwn && !publicKeyEnabled) {
    return null
  }

  return (
    <>
      {isOwn && (
        <Box
          mb={12}
          sx={{ display: 'flex', alignItems: 'center', height: '25px' }}
        >
          <Checkbox
            checked={publicKeyEnabled}
            onChange={onToggleCheckbox}
            disabled={loading}
          >
            {publicKeyEnabled ? (
              <Trans i18nKey={'share_screen_placeholder_anyone'} />
            ) : (
              <Trans i18nKey={'share_screen_placeholder'} />
            )}
          </Checkbox>
          {loading ? (
            <Icon size="22px" color="black">
              <SpinnerIcon style={{ marginLeft: '10px' }} />
            </Icon>
          ) : undefined}
        </Box>
      )}
      <CopyLink code={publicKey} active={publicKeyEnabled} />
    </>
  )
}

export default InviteLink
