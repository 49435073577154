import { calculateDaysLeftUntilDueDate } from '@hooks/useCountdownTimer'
import { Activation } from '@features/reverseTrial/models/reverseTrialModels'

const normalizeProps = (
  props: Record<string, unknown>,
  keyToDelete?: string,
) => {
  const result = props
  if (keyToDelete) {
    delete result[keyToDelete]
  }

  return result
}

export const getReverseAnalyticsGlobalParams = ({
  activation,
  trialDaysAvailable,
}: {
  activation: Activation | undefined
  trialDaysAvailable: number | undefined
}) => {
  let props: Record<string, unknown> = {
    reverse_trial: false,
    reverse_trial_days_left: null,
    reverse_trial_days: undefined,
  }

  if (activation) {
    const { isReverseTrial, expires } = activation
    const daysLeft =
      isReverseTrial && expires ? calculateDaysLeftUntilDueDate(expires) : null

    props = {
      reverse_trial: Boolean(isReverseTrial).toString(),
      reverse_trial_days_left: daysLeft,
      reverse_trial_days: trialDaysAvailable,
    }
  }

  return normalizeProps(
    props,
    !trialDaysAvailable ? 'reverse_trial_days' : undefined,
  )
}
