import { extractBoardId } from '@helpers/extractBoardId'
import {
  getBoardIdAndOwner,
  getCLeanBoardAndOwnerIds,
  getRootFolderId,
} from '@helpers/NodeIdGenerator'
import { getTimeFromNanoSeconds } from '@helpers/getTimeFromNanoSeconds'

import { convertFirestoreTimestampToDate } from '@hooks/useCountdownTimer'
import { mergeMessageWithBoard } from '../utils/mergeMessageWithBoard'
import { NodeType, Role } from '../app/models'

export function getParentFolder(state, boardId, nodeId) {
  if (!boardId) {
    return null
  }

  function findParent(folders, folderId) {
    const folder = folders[folderId]

    if (folder) {
      const item = folder.content.find((f) => f.id === nodeId)

      if (item) {
        return folder
      }

      // eslint-disable-next-line
      for (const node of folder.content) {
        if (node.type === 'folder') {
          const parentId = findParent(folders, node.id)
          if (parentId) {
            return parentId
          }
        }
      }
    }

    return null
  }

  const folders = state?.folders[boardId]
  const rootFolderId = getRootFolderId(boardId)

  if (rootFolderId === nodeId) {
    return rootFolderId
  }
  if (!folders) {
    return null
  }

  return findParent(folders, rootFolderId)
}

export function getBoard(state, boardId) {
  return state.boards[boardId]
}

export function getIsBoardActive(state, boardId) {
  return (
    state?.profileInfo?.plan?.limits?.boards === 'unlimited' ||
    state?.profileInfo?.activeBoards?.includes(boardId)
  )
}

export function unReadMessage(state, boardId, message) {
  const currentData = state.updatesReadUnRead[boardId]

  if (!currentData) {
    return false
  }

  const messageDate = getTimeFromNanoSeconds(
    message.time.seconds,
    message.time.nanoseconds,
  )
  const readDate = getTimeFromNanoSeconds(
    currentData.after.seconds,
    currentData.after.nanoseconds,
  )

  if (messageDate > readDate) {
    return true
  }

  return currentData.messages.includes(message.id)
}

export function updatesMessages(state) {
  let messages = []

  Object.values(state.updates).forEach((boardMessages) => {
    if (!boardMessages.length) {
      return
    }

    // todo rewrite getBoard method to avoid getting boards by sender and node ids;
    const { sender, node } = boardMessages[0]

    const boardId = `${sender.id}-${node}`
    const board = getBoard(state, boardId)

    messages = [
      ...messages,
      ...boardMessages.map((msg) =>
        board
          ? mergeMessageWithBoard(
              msg,
              board,
              unReadMessage(state, boardId, msg),
            )
          : msg,
      ),
    ]
  })

  return messages
}

export function updatesMessagesByBoardId(state, boardId) {
  const messages = updatesMessages(state)

  return messages
    .filter((message) => message.node === extractBoardId(boardId))
    .sort((a, b) => {
      if (a.time.seconds > b.time.seconds) {
        return -1
      }
      if (a.time.seconds < b.time.seconds) {
        return 1
      }
      return 0
    })
}

export function updatesUnReadMessagesByBoardId(state, boardId) {
  const messages = updatesMessagesByBoardId(state, boardId)

  return messages.filter((message) => message.unread)
}

export function updatesMessageById(state, messageId) {
  if (!messageId) {
    return null
  }

  const messages = updatesMessages(state)
  return messages.find((msg) => msg.id === messageId) || null
}

export function updatesMessageCount(state, message) {
  if (!message) {
    return null
  }

  const boardMessages = updatesMessagesByBoardId(
    state,
    `${message.sender}-${message.node}`,
  )

  if (!boardMessages.length || boardMessages.length === 1) {
    return 1
  }

  return boardMessages.findIndex((msg) => msg.id === message.id) + 1
}

export function updatesSavedMessage(state, board) {
  return state.updatesUnPostMessages[board] || { title: '', text: '' }
}

export function selectProfileInformation(state) {
  return {
    ...state.profileInfo,
    loaded: state.profileIsLoaded,
    forms: state.app.forms,
  }
}

export function selectedNode(state, boardId) {
  const content = getBoard(state, boardId)
  if (content === undefined) {
    return null
  }
  if (state.app.selectedNodeId === undefined) {
    return content
  }

  return content[state.app.selectedNodeId]
}

export function getFolder(state, boardId, folderId) {
  if (!boardId) {
    return undefined
  }

  const folders = state.folders[boardId]
  if (folders) {
    return folders[folderId]
  }

  return undefined
}

export function getFolders(state, boardId) {
  return state.folders[boardId]
}

export function selectBoards(state) {
  const {
    boards,
    profileInfo: { plan },
    app: { content },
  } = state
  const boardIds = Object.keys(boards)

  boardIds.forEach((board) => {
    boards[board] = {
      ...boards[board],
      isActive:
        plan?.limits?.boards === 'unlimited'
          ? true
          : state?.profileInfo?.activeBoards.includes(
              getBoardIdAndOwner(board)?.[1],
            ),
    }
  })

  const userOrder = content.order
    .concat(boardIds.filter((item) => content.order.indexOf(item) < 0))
    .filter((contentId) => boardIds?.includes(contentId))
    .sort((boardA) => (boards[boardA].isPageBoard ? -1 : 1))
  const indexOfPagesBoard = userOrder.findIndex(
    (item) => boards[item]?.isPageBoard,
  )

  const orderByActiveField = getBoardsOrderByActive(
    userOrder,
    state?.profileInfo?.activeBoards,
  )

  const order = Array.from(new Set(userOrder))
  const count = order?.length
  const activeBoardsCount =
    order?.filter((boardId) => boards?.[boardId]?.isActive)?.length || 0
  const inactiveBoardsCount = count - activeBoardsCount

  return {
    boards,
    order,
    count,
    activeBoardsCount,
    inactiveBoardsCount,
    orderByActiveField,
    indexOfPagesBoard,
  }
}

export function getBoardsOrderByActive(userOrder = [], activeBoards = []) {
  return userOrder.sort((aBoard, bBoard) => {
    const aBid = getCLeanBoardAndOwnerIds(aBoard)?.bid
    const bBid = getCLeanBoardAndOwnerIds(bBoard)?.bid

    const aIsActive = activeBoards.includes(aBid)
    const bIsActive = activeBoards.includes(bBid)

    if (aIsActive && !bIsActive) {
      return -1
    }

    if (!aIsActive && bIsActive) {
      return 1
    }

    return userOrder.indexOf(aBid) - userOrder.indexOf(bBid)
  })
}

export function getIsLoading(state) {
  return state.app.loading
}

export function selectBoardsCount(state) {
  const { boards } = selectBoards(state)

  return Object.keys(boards).length
}

export function getBoardPermissions(state, boardId) {
  return state.permissions[boardId]
}

export function getIsBoardShared(state, boardId) {
  const permissions = state.permissions[boardId]
  if (permissions) {
    return Object.keys(permissions).length > 0
  }

  return false
}

export function getBoardPermissionsLoaded(state, boardId) {
  const permission = state.permissionsLoaded[boardId]
  if (permission) {
    return permission
  }

  return false
}

export function getBoardRole(state, boardId, email) {
  const board = state.boards[boardId]
  const permissions = state.permissions[boardId]

  if (board && board.isOwn) {
    return Role.OWN
  }

  if (permissions && permissions[email]) {
    return permissions[email].permission
  }

  return Role.READ
}

export function boardBreadcrumbs(state, boardId, folderId) {
  const board = getBoard(state, boardId)
  const folders = state.folders[boardId]
  if (!board || !folders) {
    return []
  }

  const rootFolder = folders[board.rootFolderId]

  return findPath(folders, rootFolder, folderId, [])
}

const findPath = (folders, folder, id, path) => {
  if (!Array.isArray(folder?.content)) {
    return []
  }

  const newPath = [...path]
  newPath.push({ id: folder.id, icon: folder.icon, title: folder.title })

  if (id === undefined || String(folder.id) === String(id)) {
    return newPath
  }

  const nodes = folder.content.filter((f) => f.type === NodeType.FOLDER)

  // eslint-disable-next-line
  for (const f of nodes) {
    const ffolder = folders[f.id]

    const result = findPath(folders, ffolder, id, newPath)
    if (result.length > 0) {
      return result
    }
  }

  return []
}

export const selectMaxBoardsMembers = (state) => {
  return Object.keys(state.boards).reduce((prev, currentValue) => {
    const board = state.boards[currentValue]

    if (board.isOwn && board.summary?.permissions?.total > prev) {
      return state.boards[currentValue].summary.permissions.total
    }

    return prev
  }, 0)
}

export const selectNumberOfSponsoredBoards = (state) => {
  return Object.keys(state.boards).reduce((prev, currentValue) => {
    if (state.boards[currentValue].options?.notCountedTowardsLimit) {
      return prev + 1
    }

    return prev
  }, 0)
}

export const appLoading = (state) => state.app.loading
export const getCurrentFolderId = (state) => state.app.currentFolderId

export const selectUndoStack = (state) => state.app.undoStack

export const selectActionInProgress = (state) => state.app.actionInProgress
export const getUploadingNodes = (state) => state.app.uploading
export const getInvite = (state, boardId) => state.invites[boardId]
export const getSubscriptionPlans = (state) => state.plans
export const getBulkActionNodeTypes = (state) => {
  const { nodes, contentId, folderId } = state.app.bulk
  const folder = getFolder(state, contentId, folderId)

  return (folder?.content || [])
    .filter(({ id }) => nodes.includes(id))
    .map(({ type }) => type)
}
export const campaignsBanners = (state) => state.campaignsBanners
export const getClientConfig = (state) => state.app.clientConfig

export const getFirstActivation = (state) => {
  const { profileInfo } = state

  const activations = profileInfo.plan.activations || []

  const activation = activations[0]
    ? {
        ...activations[0],
        expires: activations[0].expires
          ? convertFirestoreTimestampToDate(activations[0].expires)
          : undefined,
      }
    : undefined

  return activation
}
