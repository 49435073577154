import { FC, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Overlay } from 'boards-web-ui'

import useBoard from '@hooks/useBoard'
import useFolder from '@hooks/useFolder'

import usePageAPI from '@features/toolbar/hooks/usePageAPI'
import usePageEvents from '@features/analytics/usePageEvents'
import PageEditor from '@features/editorPages/components/PageEditor'
import { ICreateOrUpdateNode } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

import { Folder, Node, NodeType } from 'app/models'
import { generateFolderId, getNodeId } from '@helpers/NodeIdGenerator'
import { CSS_TRANSITION_TIME } from 'constants/CSSTransitionTime'
import { SelectActionProps } from '@features/pickers'
import useAutoPopulatePageTitle from './hooks/useAutoPopulatePageTitle'

const defaultPage = {
  id: '',
  icon: '',
  title: '',
  type: NodeType.FOLDER,
  isPage: true,
  shortcut: '',
  content: [],
}

const CreatePage: FC = () => {
  const ref = useRef<string>()
  const navigate = useNavigate()
  const { create } = usePageAPI()
  const { hidePageHint } = useProfileInformation()
  const { createPageEvent, pageCreateContentEvent } = usePageEvents()
  const {
    boardId,
    folder: sourceFolder,
    isReadOnly,
    board: { isPageBoard },
  } = useBoard()
  const { folder } = useFolder(boardId, ref.current)
  const [title, setTitle] = useState<string>('')
  const [displayEditor, setDisplayEditor] = useState(false)
  const autoPopulatePageTitle = useAutoPopulatePageTitle()

  useEffect(() => {
    setDisplayEditor(true)
  }, [])

  const createPage = (
    nodes: Node[],
    extraParams?: { [id: string]: string },
  ) => {
    const { nodeId: folderId, key: folderKey } = generateFolderId()
    ref.current = folderId

    const titleWithDefaults = autoPopulatePageTitle(nodes, title)
    const pageNodes = nodes.map((n, index) => {
      const nodeId = getNodeId(index + 1, folderKey)
      return {
        ...n,
        id: nodeId,
      }
    })

    const pageProps = {
      title: titleWithDefaults,
      unbranded: true,
      content: pageNodes,
    }

    const newFolder = {
      ...pageProps,
      id: folderId,
      content: pageNodes,
    } as Folder

    createPageEvent(newFolder, boardId, isPageBoard)

    pageNodes.forEach((pageNode) => {
      pageCreateContentEvent(pageNode, boardId, folderId, extraParams)
    })

    create(boardId, sourceFolder.id, folderKey, pageProps)

    hidePageHint()

    return { folderId, folderKey }
  }

  const handleOnDoneClick = () => {
    if (title) {
      createPage([])
    }

    setDisplayEditor(false)

    setTimeout(() => {
      navigate(`../`)
    }, 400)
  }

  const handleUpdateNode = () => {
    navigate(`../edit-page/${ref.current}?create=true`, { replace: true })
  }

  const handleCreateNode: ICreateOrUpdateNode = (node, extraParams) => {
    const { folderId, folderKey } = createPage([node as Node], extraParams)

    if (node.type !== NodeType.VIDEO_STREAMING) {
      navigate(`../edit-page/${folderId}?create=true`, { replace: true })
    }

    return getNodeId(1, folderKey)
  }

  const handleSelectAction = (selectAction: SelectActionProps) => {
    const nodes = selectAction.nodes.map((node) => {
      if (node.type === NodeType.CHECKOUT || node.type === NodeType.FORM) {
        // spreadsheet URL and encrypted Spreadsheet
        // URL fields should be deleted from the object
        // to avoid starting of google integration process on server side
        // TODO remove disable line rule when PageNode types will be fixed;
        // eslint-disable-next-line
        const { spreadsheetURL, encryptedSpreadsheetURL, ...copyNodeForm } =
          node?.form || {}
        return {
          ...node,
          form: {
            ...copyNodeForm,
          },
        }
      }

      return node
    })

    const { folderId } = createPage(nodes)

    navigate(`../edit-page/${folderId}?create=true`, { replace: true })
  }

  if (isReadOnly) return null

  return (
    <Overlay index={0} position="fixed" color={'rgba(0, 0, 0, 0.2)'}>
      <CSSTransition
        in={displayEditor}
        classNames="create-page"
        timeout={CSS_TRANSITION_TIME}
      >
        <PageEditor
          isNew
          title={title}
          folder={folder || defaultPage}
          updateTitle={setTitle}
          createNode={handleCreateNode}
          updateNode={handleUpdateNode}
          onClickDone={handleOnDoneClick}
          selectAction={handleSelectAction}
        />
      </CSSTransition>
    </Overlay>
  )
}

export default CreatePage
