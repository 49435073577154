import React, { FC, HTMLAttributes, PropsWithChildren } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BackIcon, ButtonIcon, Tooltip, TooltipContent } from 'boards-web-ui'

import { IconWrapper } from '@ui/components/IconWrapper'
import CloseButtonWithTooltip from '../../../../ui/components/CloseButtonWithTooltip'

import { useCheckout } from '../hooks'
import { CheckoutActionsIdsEnum } from '../models'

import CheckoutCurrency from './CheckoutCurrency'
import styles from './CheckoutHeaderControls.module.css'

type CheckoutHeaderControlsProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    onClose: () => void
    title?: string
  }
>
const CheckoutHeaderControls: FC<CheckoutHeaderControlsProps> = ({
  onClose,
  children,
  ...rest
}) => {
  const { t } = useTranslation()
  const { action } = useCheckout()

  return (
    <div className={styles.CheckoutHeaderControlsRoot} {...rest}>
      {action?.onCancel ? (
        <Tooltip
          data={
            <TooltipContent>
              <Trans i18nKey={'action_back'} />
            </TooltipContent>
          }
        >
          <ButtonIcon size={38} variant="gray" onClick={action?.onCancel} round>
            <IconWrapper>
              <BackIcon />
            </IconWrapper>
          </ButtonIcon>
        </Tooltip>
      ) : (
        <div className={styles.Space}></div>
      )}

      <div className={styles.CheckoutHeaderControlsTitle}>
        {action?.title ? <h3>{t(action.title)}</h3> : null}
        {action?.id === CheckoutActionsIdsEnum.PRODUCTS ? (
          <CheckoutCurrency />
        ) : null}
        {children}
      </div>
      <CloseButtonWithTooltip
        className={styles.CheckoutHeaderControlsClose}
        onClose={onClose}
        gray
      />
    </div>
  )
}

export default React.memo(CheckoutHeaderControls)
