import { FC, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Link from '../../../../elements/Link'
import { SimpleButton } from '../../../../ui/components/SimpleButton'

import useSidebarEvents from '../../analytics/useSidebarEvents'

import { ReactComponent as LearnMoreIcon } from '../icons/LearnMore.svg'
import { ReactComponent as CloseIcon } from '../icons/Close.svg'
import styles from './SideBarMonetizationBanner.module.css'

interface Props {
  onClose: () => void
  isCountrySingleTier: boolean
}
const MonetizationBanner: FC<Props> = ({ onClose, isCountrySingleTier }) => {
  const { t } = useTranslation()
  const { clickBannerEvent, showBannerEvent } = useSidebarEvents()

  useEffect(() => {
    showBannerEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.Root}>
      <SimpleButton
        className={styles.CloseIcon}
        customSize={34}
        onClick={onClose}
        darkGray
        mediumBtnIcon
      >
        <CloseIcon />
      </SimpleButton>

      <div className={styles.Title}>
        <Trans i18nKey="monetization_banner_pre_launch_title_emoji" />
      </div>

      <Link
        to={t(
          isCountrySingleTier
            ? 'monetization_banner_pre_launch_link_single_tier'
            : 'monetization_banner_pre_launch_link',
        )}
        target="_blank"
        onClick={clickBannerEvent}
      >
        <span className={styles.LearnMore}>
          <Trans i18nKey="learn_more" /> &nbsp;
          <LearnMoreIcon className={styles.Icon} />
        </span>
      </Link>
    </div>
  )
}

export default MonetizationBanner
